.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.bg_hight_1{
  height: 100vh;
  
}

.bg_hight_2{
  height: 100vh;
  
}

.bg{
  background-image: url("img/bg.jpg");
  height: auto;

}
.navbar{
  background-image: url("img/bg.jpg");
}
.bg_footer{
  background-image: url("img/footer.jpg");
  height: auto;
  

}

.menu_btn{
  font-size: 30px;
  font-weight: bold;
  padding: 10px;
  margin: 10px;
}

.button_reserv {
  background-color: transparent;
  border: 4px solid #E7F3E3; /* white */ 
  margin: 4px;
  padding: 20px;
  color: #E7F3E3;
  width: 200px;
}

.button_menu {
  background-color: #E7F3E3;
  color: aliceblue;
  border: 4px solid #E7F3E3; /* white */ 
  margin: 4px;
  padding: 20px;
  width: 200px;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-image: url("img/bg.jpg");
  min-width: 1080px;
  min-height: 512px;
  padding: 12px 16px;
  z-index: 1;
  
}

.b1{
  color: #E7F3E3;
  padding: 40px;
}

.b2{
  font-size: 200;
  font-weight: 300;
}

.drop_img_1{
  position:absolute;
  left: 450px;
  top: 100px
}
.drop_img_2{
  position:absolute;
  left:0px;
  top: 200px
}
.drop_img_4{
  position:absolute;
  right: 250px;
  top: 250px
}

.drop_img_5{
  position:absolute;
  right: 130px;
  bottom:15px;
  
}
.drop_img_3{
  position:absolute;
  right: 20px;
  bottom:20px
}

.drop_head_1{
  position: absolute;
  left: 40px;
  top:50px;
}

.drop_head_2{
  position: absolute;
  right: 40px;
  bottom:70px;
}

.drop_head_3{
  position: absolute;
  left: 70px;
  bottom:35px;
}

.drop_head_4{
  position: absolute;
  left: 330px;
  bottom:50px;
}

.text_animia{
  color: #E7F3E3;
  font-weight: bolder;
 

}

.drop_head_mob1{
  color: white;
  text-shadow: #fff;
  padding: 5px;
  font-size: 35px;
  font-weight: 900;
  text-decoration: none;
}

.navbar-toggler-icon {
  color: white; /* Change this to the desired color */
}


*, *::after, *::before{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.hline{
  
  padding-bottom: 0.25rem;
  text-decoration: none !important;
  
}

.hline:visited
{
  color: white;
}

.hline::before{
  content: " ";
  position: absolute;
  right: 0;
  bottom: 0;
  width: 0;
  height: 2px;
  background-color: white;
  transition: width 0.25s ease-out
}

.hline:hover::before{
  width: 100%;
}


.top_block{
  background-color: orange;
}

.text_ptn h1{
font-size: 110px;
font-weight: 160;
margin-left: -10px;
color: transparent;
-webkit-text-stroke: 1px #fff;
background-image: url("img/pixlated.png");
-webkit-background-clip: text;
background-position: 0 0 ;
animation: back 20s linear infinite;
}

@keyframes back{
  100%{
    background-position: 2000px 0px;
  }
}

.text_ptn_m h1{
  font-size: 50px;
  font-weight: 160;
  margin-left: -10px;
  color: transparent;
  -webkit-text-stroke: 1px #fff;
  background-image: url("img/pixlated.png");
  -webkit-background-clip: text;
  background-position: 0 0 ;
  animation: back 20s linear infinite;
  }
  
  @keyframes back{
    100%{
      background-position: 2000px 0px;
    }
  }

.image2_2section{
  position:absolute;
  right: 0px;
  bottom: 0px;
}

.arrow-body{
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.arrow{
  position: relative;
  width: 60px;
  height: 60px;
  border-top: 12px solid #fff;
  border-left: 12px solid #fff;
  transform:  rotate(145deg);
  animation: arrow-load 2s infinite;
}

.arrow:nth-child(1)
{
  left: 25px;
  animation-delay: -0.4s;
}
.arrow:nth-child(2)
{
  animation-delay: -0.2s;
}
.arrow:nth-child(3)
{
  right: 25px;
}

@keyframes arrow-load
{
  0%
  {
    opacity: 0;
    transform: rotate(145deg)  translate(60px,60px);
  }
  0%
  {
    opacity: 1; 
  }
  100%
  {
    opacity: 0;
    transform: rotate(145deg)  translate(-80px,-60px);
  }
}


.order_button
{
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 40px;
  font-weight: 800;
  gap: 20px;
  padding: 20px;
  border-radius: 25px;
  background: linear-gradient(grey,b);
  border: 2px solid #282c34;
  box-shadow: inset 0 5px 1px rgba(0, 0n, 0, .35), 0 5px 5px,rgba(0, 0n, 0, .5), 0 15px 25px,rgba(0, 0n, 0, .35);
}

.order_button label span::before
{
  content: '';
  position: absolute;
  inset: 5px 3px;
  border-top: 1px solid #ccc;
  filter: blur(2px);
}



.delivery{
  align-items: center;
  justify-content: center;

  padding: 3px;
}