@font-face {
    font-family: "SAMAN___";
    src: url("./font/SAMAN___.TTF") format("truetype");
  }

body{
    font-family: "SAMAN___", sans-serif;
}

.box{
    width: fit-content;
    background: linear-gradient(to bottom, #F2ECE3, #F4DDBD);
    position: relative;
    padding: 20px;
    border-radius: 10px;
    align-items: center;
    text-align: center;
    margin: 40px;
}



.box_name{
    width: 40%;
    height: 100%;
    background-color: white;
    padding: 10px;
    border-radius: 50px;
    align-items: center;
    text-align: center;
    opacity: 90%;
    margin: 5px;
}

h1{
    font-size: 30px;
    font-weight: 900;
    padding: 30px;
}

.add{
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 1px;
    padding: 30px;
    text-decoration: none;
}

.box_number{
    width: 20%;
    height: 100%;
    background-color: white;
    padding: 10px;
    border-radius: 50px;
    align-items: center;
    text-align: center;
    opacity: 90%;
    margin: 5px;
}

.box_date{
    width: 20%;
    height: 100%;
    background-color: white;
    padding: 10px;
    border-radius: 50px;
    align-items: center;
    text-align: center;
    opacity: 90%;
    margin: 5px;
    align-items: center;
}

.box_guest{
    width: 20%;
    height: 100%;
    background-color: white;
    padding: 10px;
    border-radius: 50px;
    align-items: center;
    text-align: center;
    opacity: 90%;
    margin: 5px;
    align-items: center;
}

.box_time{
    width: 20%;
    height: 100%;
    background-color: white;
    padding: 10px;
    border-radius: 50px;
    align-items: center;
    text-align: center;
    opacity: 90%;
    margin: 5px;
    align-items: center;
}

.submit{
    width: 20%;
    height: 100%;
    background-color: #326278;
    padding: 10px;
    border-radius: 10px;
    align-items: center;
    text-align: center;
    margin: 5px;
    align-items: center;
}

.center{
    text-align: center;
    align-items: center;
    justify-content: center;
}
