*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: sans-serif;
}

.body{
    background-color: aqua;
    color: black;
}

.container{
    max-width: 1300px;
    width: 100%;
    margin: auto;
    padding: 0 20px;
}

.section {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.h1{
    font-size: 50px;
    line-height: 60px;
    align-items: center;
    margin-bottom: 40px;
}

.cards{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.card{
    flex: 0 0 calc(33.33% - 20px);
    max-width: calc(33.33 - 20px);
    width: 100%;
    background: #326278;
    box-shadow: grey 2px 3px;
    color: white;
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 20px;
    transition: .3s;
    border: 20x solid transparent;

}

.card:hover{
    border-color: rgb(0, 0, 0);
}

@media(max-width:968px){
    section{
        padding:  20px 0px;
    }
    h1{
        font-size: 30px;
        line-height: 40px;
    }
    .card{
        flex: 0 0 calc(50% - 20px);
        max-width: calc(50% - 30px);
    }
}

@media(max-width:600px){
    .card{
        flex: 0 0 100%;
        max-width: 100%;
    }
}

.btn_tag{
    color: #326278;
    font-weight: bolder;
    font-size: 20px;
    padding: 20px;
}

.flower{
    position: absolute;
    left: 0px;
    
}